<template>
  <CRow class="weather-wrapper position-relative overflow-hidden">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات هواشناسی..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="position-absolute sunnyImage"></div>
    <CCol class="py-3 d-flex justify-content-between flex-column">
      <CRow class="z-1 position-relative gap-5">
        <CCol col="12">
          <h6 class="advice_Title text-black p-2">
            ←
            {{ advice_Title }}
          </h6>
        </CCol>
        <CCol>
          <ul class="adviceBox p-0">
            <li
              :class="`ml-2 animate__animated animate__fadeInRightBig`"
              v-for="(item, index) in adviceMessage"
              :key="index"
            >
              <CIcon
                name="cilWarning"
                size="lg"
                class="ml-2 animate__animated animate__flash animate__infinite animate__slow"
              />
              <span>{{ item }}</span>
            </li>
          </ul>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CRow>
            <CCard class="currentDayBoxInfo blur-card text-white p-3">
              <CCol class="d-flex align-items-center gap-5">
                <h6 class="m-0">هم اکنون</h6>
              </CCol>
              <hr class="m-2" style="border-color: #888" />
              <CCol class="d-flex align-items-center gap-5">
                <h6 class="text-white-90">رطوبت نسبی (درصد):</h6>
                <h5 class="d-flex flex-grow-1 justify-content-end">
                  {{ weather.current.humidity }}٪
                </h5>
              </CCol>
              <CCol class="d-flex align-items-center gap-5">
                <h6 class="text-white-90">سرعت باد (کیلومتر بر ساعت):</h6>
                <h5 class="d-flex flex-grow-1 justify-content-end">
                  {{ weather.current.wind_speed }}
                </h5>
              </CCol>
              <CCol class="d-flex align-items-center gap-5">
                <h6 class="text-white-90">ابرناکی (درصد):</h6>
                <h5 class="d-flex flex-grow-1 justify-content-end">
                  {{ weather.current.clouds }}٪
                </h5>
              </CCol>
            </CCard>
          </CRow>
        </CCol>
        <CCol col="auto">
          <CRow
            class="align-items-center text-black position-relative current-weather-wrapper"
          >
            <CCol col="auto" class="p-0">
              <vue-skycons
                color="#333"
                size="140"
                :condition="getTypeIcon(weather.current.weather[0].icon)"
              />
            </CCol>
            <CCol col="auto" class="p-0">
              <CRow class="flex-column align-items-center gap-10">
                <CCol col="auto" class="font-2xl d-flex gap-5">
                  <span>℃</span><span>{{ weather.current.temp }}</span>
                </CCol>
                <CCol col="auto">
                  <h4>
                    {{ weather.current.weather[0].description }}
                  </h4>
                </CCol>
                <CCol col="12">
                  <h6 class="text-dark d-flex gap-5">
                    <CIcon name="cilLocationPin" />
                    <span>{{ $route.params.farmName }}</span>
                  </h6>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" class="allDayBox">
          <CRow class="gap-20">
            <CCol
              class="blur-card cursor-pointer py-3 daily-weather-wrapper"
              v-for="(day, index) in weather.daily"
              :key="index"
              @click="
                getWeatherMessage(
                  day.date,
                  ((day.temp.max + day.temp.min) / 2).toFixed(0),
                  day.temp.min.toFixed(0),
                  day.temp.max.toFixed(0),
                  day.humidity,
                  (day.wind_speed * 3.6).toFixed(2),
                  (day.pop * 100).toFixed(0),
                  day.visibility
                )
              "
            >
              <h5 class="text-white-50 text-center mx-3">{{ day.date }}</h5>
              <div class="d-flex flex-column align-items-center my-3 gap-5">
                <vue-skycons
                  class="allDayBox_Card_icon"
                  :color="getColorIcon(day.weather[0].icon)"
                  size="35"
                  :condition="getTypeIcon(day.weather[0].icon)"
                />
                <div
                  class="d-flex justify-content-center flex-column gap-5 temp"
                >
                  <h3 class="text-white text-center">
                    ℃{{ ((day.temp.max + day.temp.min) / 2).toFixed(0) }}
                  </h3>
                  <h6 class="weather-title">
                    {{ day.weather[0].description }}
                  </h6>
                </div>
              </div>
              <div class="allDayBox_Card_allItemBox">
                <h5>حداکثر دما:</h5>
                <h6 class="max-temp">℃{{ day.temp.max.toFixed(0) }}</h6>
              </div>
              <div class="allDayBox_Card_allItemBox">
                <h5>حداقل دما:</h5>
                <h6 class="min-temp">℃{{ day.temp.min.toFixed(0) }}</h6>
              </div>
              <div class="allDayBox_Card_allItemBox">
                <h5>رطوبت:</h5>
                <h6>{{ day.humidity }}٪</h6>
              </div>
              <div class="allDayBox_Card_allItemBox">
                <h5>سرعت وزش باد:</h5>
                <h6>{{ (day.wind_speed * 3.6).toFixed(2) }}</h6>
              </div>
              <div class="allDayBox_Card_allItemBox">
                <h5>ابرناکی:</h5>
                <h6>{{ day.clouds }}٪</h6>
              </div>
              <div class="allDayBox_Card_allItemBox">
                <h5>احتمال بارندگی:</h5>
                <h6>{{ (day.pop * 100).toFixed(0) }}٪</h6>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "WeatherForm",
  props: {
    coordinate: {
      type: Array,
    },
  },
  data() {
    return {
      weather: null,
      advice_Title:
        "توصیه های امروز زمین ( با کلیک بر روی هر روز توصیه های آن روز را ببینید)",
      adviceMessage: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions("weather", ["GetMessage", "GetWeather"]),
    async getWeather() {
      this.loading = true;
      var result = await this.GetWeather({
        lat: this.$route.params.coordinate[1],
        lon: this.$route.params.coordinate[0],
      });
      this.loading = false;
      if (result.succeeded) {
        let weather = result.data;
        let date = new Date((weather.current.dt + 16200) * 1000).toLocaleString(
          "fa",
          {
            weekday: "long",
          }
        );
        weather.current.data = date;
        weather.daily.forEach((day) => {
          let date = new Date(
            (day.dt + weather.timezone_offset) * 1000
          ).toLocaleString("fa", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
          });
          day.date = date;
        });
        weather.daily.shift();
        this.getWeatherMessage(
          null,
          null,
          null,
          null,
          weather.current.wind_speed,
          weather.daily[0].pop,
          weather.current.visibility
        );
        this.weather = weather;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
    },
    getColorIcon(type) {
      switch (type) {
        case "01d":
          return "#aaa";
        case "02d":
          return "#aaa";
        case "03d":
          return "#aaa";
        case "04d":
          return "#aaa";
        case "09d":
          return "#aaa";
        case "10d":
          return "#aaa";
        case "11d":
          return "#aaa";
        case "13d":
          return "#aaa";
        case "50d":
          return "#aaa";
        default:
          return "#aaa";
      }
    },
    getTypeIcon(type) {
      switch (type) {
        case "01n":
          return "clear-night";
        case "01d":
          return "clear-day";
        case "02d":
          return "partly-cloudy-day";
        case "03d":
          return "cloudy";
        case "04d":
          return "cloudy";
        case "09d":
          return "sleet";
        case "10d":
          return "rain";
        case "11d":
          return "fog";
        case "13d":
          return "snow";
        case "50d":
          return "fog";
        default:
          return ``;
      }
    },
    async getWeatherMessage(
      day,
      temp,
      minTemp,
      maxTemp,
      humidity,
      windSpeed,
      chanceOfRain,
      visibility
    ) {
      let condition = {
        temp: temp,
        maxTemp: maxTemp,
        minTemp: minTemp,
        humidity: humidity,
        windSpeed: windSpeed,
        chanceOfRine: chanceOfRain,
        visibility: visibility,
      };

      var result = await this.GetMessage({ condition });
      if (result) {
        if (result.messages.length > 0) this.adviceMessage = result.messages;
        else {
          this.adviceMessage = [];
          this.adviceMessage.push("هیچ توصیه ای یافت نشد");
        }
        if (day) this.advice_Title = `توصیه های  ${day}`;
      } else {
        this.adviceMessage = [];
        this.adviceMessage.push("هیچ توصیه ای یافت نشد");
      }
    },
  },
  mounted() {
    this.getWeather();
  },
};
</script>

<style scoped>
.weather-wrapper {
  height: 95dvh;
  padding: 40px 20px;
}

.sunnyImage {
  background-size: cover;
  background-image: url("../../assets/img/weather/weather-bg.jpg");
  background-repeat: no-repeat;
  filter: blur(9px);
  inset: -20px;
}

.currentDayBoxInfo h5 {
  font-size: 18px;
}
.currentDayBoxInfo h6 {
  font-size: 15px;
}

.current-weather-wrapper {
  position: relative;
  left: 80px;
}

.daily-weather-wrapper .temp {
  color: #000;
}
.daily-weather-wrapper .max-temp {
  color: #ff9696;
}
.daily-weather-wrapper .min-temp {
  color: #9ad4ff;
}
.weather-title {
  font-size: 11px;
  color: #fff !important;
}

.allDayBox_Card_allItemBox {
    display: flex;
    justify-content: flex-start;
    color: rgb(0, 0, 0);
    margin: 2px 0px;
    padding: 1px 2px;
    border-bottom: 1px solid #757575;
}

.allDayBox_Card_allItemBox h5 {
  color: #d9d9d9;
  flex-basis: auto;
  flex-grow: 1;
  font-size: 13px;
}

.allDayBox_Card_allItemBox h6 {
  flex-basis: auto;
  font-size: 17px;
  color: #fff;
}

.adviceBox {
  display: inline-flex;
  list-style: none;
  width: auto;
  flex-basis: auto;
  border-radius: var(--border-radius);
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 15px;
  flex-direction: column;
  gap: 12px;
}

.adviceBox li {
  color: #ffffff;
  background: indianred;
  padding: 13px;
  border-radius: var(--border-radius);
  width: auto;
}
</style>
